import Vue from 'vue'
import App from './App.vue'
import router from "@/router/router";
import store from "@/store/store";
import '@mdi/font/css/materialdesignicons.css'
import "@/styles/global.scss";
import VueTheMask from 'vue-the-mask';
import vuetify from './plugins/vuetify';
import {vMaska} from "maska/vue";

Vue.use(VueTheMask);
Vue.directive('maska', vMaska)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
